.miew-canvas > canvas {
  display: block;
  width: 100%;
  height: 100%;
}

.miew-container,
.miew-canvas {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

$back-gray: 32;
$dark-gray: rgba(64, 64, 64, 0.75);
$titlebar-color: silver;
$dark-color: #202020; // FIXME: $back-gray
$light-color: #ccc;

.miew-message {
  // hack for centered error-reporting
  background-color: $light-color;
  display: table;
  width: 100%;
  height: 100%;

  p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: large;
  }
}

.mode-message {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 4px;
  color: $titlebar-color;
  background-color: $dark-gray;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: left;
  -ms-flex-pack: initial;
  justify-content: left;
  opacity: 0;
}

.atom-info {
  margin: 10px;
  text-align: left;
}

.overlay {
  pointer-events: none;
}

.theme-dark {
  .label-obj,
  .label-fg {
    color: $light-color;
    background-color: $dark-color;
  }

  .label-bg {
    color: $dark-color;
    background-color: $light-color;
  }
}

.theme-light {
  .label-obj,
  .label-fg {
    color: $dark-color;
    background-color: $light-color;
  }

  .label-bg {
    color: $light-color;
    background-color: $dark-color;
  }
}
